import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const LoadingIcon = () => {
  return (
    <FontAwesomeIcon icon={faSpinner} spin size="1x" />
  );
};

export default LoadingIcon;
