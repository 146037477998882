import React,{ useState, useEffect } from "react";
import { urlDistribution, endpointChannels, endpointDownloadZip, url, urlDelivery } from "../services/Endpoints";
import { Loading } from "./Loading";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIcon from "./LoadingIcon";

export function Channels(props){

    const [channels, setChannels] = useState([]);
    const [show, setShow] = useState([]);
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);

    useEffect(() => {
      getChannels();
        return() => {
          setChannels(() => [])
        }
    }, []);

    const handleClickUpdate = (e) => {
        e.preventDefault();
        getChannels()
    }

    const handleClickShowNotifications = (e, channel) => {
      e.preventDefault();

      const updatedChannels = channels.map((element) => {
          if (channel === element.channel && element.show == false) {
              return { ...element, show: true };
          } else {
              return { ...element, show: false };
          }
      });

      setChannels(updatedChannels);
    }

    const handleClickDownload = (e, alias, account, inkId) => {
            e.preventDefault();
            //setDownloading(true)

            setDownloading((prev) => ({ ...prev, [inkId]: true }));

            fetch(`${urlDelivery}${endpointDownloadZip}?channel=${alias}&account=${account}&ink_id=${inkId}`)
            .then(response => response.blob())
            .then(blob => {
                // Crea un objeto URL para el archivo zip y crea un enlace de descarga
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', account+'-'+inkId+'.zip');
                document.body.appendChild(link);
                link.click();
                setDownloading((prev) => ({ ...prev, [inkId]: false }));
            })
            .catch((error) => {
              setDownloading((prev) => ({ ...prev, [inkId]: false }));
              console.error(error);
              }
            )
            
    }

    const getChannels = async (event) => {
        try {
          setLoading(true);
          const response = await fetch(`${urlDistribution}${endpointChannels}?content_type=all`, {
            method: "GET",
            data: {content_type: 'all'},
            headers: {
              "Content-Type": "application/json"
            }
          });
          const res = await response.json();
          console.log(res.data.status);
          if(res && res.status == 'success'){
            setChannels(res.data.channels);

          }else{
            
          }
          setLoading(false);

        } catch (error) {
          setLoading(false);
          console.error("Request failed:", error);
        }
    };

    return(
        <div>
          <button onClick={(e) => handleClickUpdate(e)}>Actualizar</button>
          {loading == true &&
            <Loading />
          }
          <ul className="channels-list">
          {
            channels.map((channel, index) => {
              return  <>
                      <li key={index} onClick={(e) => handleClickShowNotifications(e, channel.channel)} className="channel-item">
                        <div className="d-flex">
                          <div>
                            {channel.channel}
                          </div>
                          {channel.notification_count > 0 && 
                            <div className="notification-count">
                              {channel.notification_count}
                            </div>
                          }
                        </div>
                      </li> 
                        {channel.show == true &&
                          <table>
                            <thead>
                              <tr><th>Ink id</th><th>Notificación</th><th></th></tr>
                            </thead>
                            <tbody>
                              {channel.notifications.map((notification, index) => {
                                return  <tr key={index}>
                                          
                                          <td>{notification.account}-{notification.ink_id}</td>
                                          <td>{notification.notification_type}</td>
                                          {channel.channel == 'Spotify' && 
                                            <td><button onClick={(e)=>handleClickDownload(e, 'spotify', notification.account, notification.ink_id)} disabled={downloading[notification.ink_id]}>
                                              {!downloading[notification.ink_id] && <FontAwesomeIcon  icon={faDownload} /> }
                                              {downloading[notification.ink_id] && <LoadingIcon /> }
                                              
                                              </button>
                                            </td>
                                          }
                                          
                                        </tr>
                              })}
                              </tbody>  
                          </table>
                        }
                      </>
            })
          }
          </ul>
        
        </div>
    )  
}

export default Channels